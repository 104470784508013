import React from "react"
import SbEditable from "storyblok-react"
import { decode } from "html-entities"

const ProductDataBlock = ({ block, language }) => {
  return (
    <SbEditable content={block}>
      <section className="py-10 -mb-px border-b border-gray-100">
        <div className="mx-auto max-w-screen-xl px-grid">
          {block.dataContent?.length > 0 && (
            <div className="flex flex-row flex-wrap -mx-grid mb-8">
              <div className="w-full lg:w-1/4 px-grid">
                <span className="block py-2 font-bold text-primary">
                  {block.dataTitle}
                </span>
              </div>
              <div className="w-full lg:w-3/4">
                <table className="w-full table-auto">
                  <tbody className="text-sm leading-6 align-baseline">
                    {block.dataContent?.length > 0 &&
                      block.dataContent.map((item, index) => (
                        <React.Fragment key={`product-data-content-${index}`}>
                          {item.value?.Value ? (
                            <>
                              {item.value.Value !== "0,000 X 0,000 X 0,000 " &&
                                item.value.Value !== "0,000 " && (
                                  <tr>
                                    <td className="w-1/2 py-2 lg:w-1/3 px-grid">
                                      {language === "de"
                                        ? item.label
                                        : item.translated_label}
                                    </td>
                                    <td className="w-1/2 py-2 lg:w-2/3 px-grid">
                                      {item.value.Value}
                                    </td>
                                  </tr>
                                )}
                            </>
                          ) : (
                            item.data?.map((c, innerindex) => (
                              <tr
                                key={`product-data-content-inner-${innerindex}`}
                              >
                                <td className="w-1/2 py-2 lg:w-1/3 px-grid">
                                  {innerindex === 0
                                    ? item.translated_label
                                    : ""}
                                </td>
                                <td className="w-1/2 py-2 lg:w-2/3 px-grid">
                                  {decode(c)}
                                </td>
                              </tr>
                            ))
                          )}
                        </React.Fragment>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </section>
    </SbEditable>
  )
}

export default ProductDataBlock
